import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import { watchViewport, unwatchViewport } from 'tornis'
import { setHeaderTheme } from '../actions/headerTheme'
import onPageRender from '../hocs/onPageRender'
import ContactAddress from '../components/ContactAddress/ContactAddress'
import ContactList from '../components/ContactList/ContactList'
import Footer from '../components/Footer/Footer'
import Head from '../components/Head/Head'

const classes = {
  container: [
    'md:flex',
    'md:flex-col',
    'md:justify-center',
    'py-20',
    'px-6',
    'bg-brown-300',
    //
  ].join(' '),
}

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      minHeight: 'none',
    }
    this.setContainerSize = this.setContainerSize.bind(this)
  }

  componentDidMount() {
    const { setHeaderTheme } = this.props
    setHeaderTheme('goldOnDarkWithBg')
    watchViewport(this.setContainerSize)
  }

  componentWillUnmount() {
    unwatchViewport(this.setContainerSize)
  }

  setContainerSize({ size }) {
    if (!size.changed) return

    const { x, y } = size
    let minHeight

    if (x >= 640) {
      minHeight = `${y}px`
    } else {
      minHeight = `${y - 45}px`
    }

    this.setState({ minHeight })
  }

  render() {
    const { data } = this.props
    const { address, contact, title } = data.page.data
    const { fields } = data.page
    const { pagePath } = fields
    const { minHeight } = this.state
    return (
      <div className={classes.container} style={{ minHeight }}>
        <Head title={title.text} path={pagePath} />
        <ContactAddress items={address.raw} />
        <ContactList items={contact.raw} />
        <Footer theme='brown' removeContact />
      </div>
    )
  }
}

Contact.propTypes = {
  data: PropTypes.object.isRequired,
  setHeaderTheme: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    setHeaderTheme: theme => dispatch(setHeaderTheme(theme)),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(onPageRender(Contact))

export const query = graphql`
  query($id: String!) {
    page: prismicContact(id: { eq: $id }) {
      data {
        title {
          text
        }
        address {
          raw
        }
        contact {
          raw
        }
      }
      fields {
        pagePath
      }
    }
  }
`
