import React, { Component } from 'react'
import PropTypes from 'prop-types'
import fitty from 'fitty'
import styles from './ContactAddress.module.scss'

/**
 * @param {Array} items
 * @param {String} text
 */
function hasLongestText(items, text) {
  const longest = items.reduce((accumulator, current) => {
    return accumulator.text.length > current.text.length ? accumulator : current
  })
  return longest.text === text
}

const classes = {
  list: [
    'font-serif-display',
    'uppercase',
    'tracking-tighter',
    'leading-none',
    'lg:text-center',
    'text-gold-100',
    //
  ].join(' '),
  fittedItem: [
    'mr-auto',
    'ml-auto',
    'w-full',
    //
  ].join(' '),
}

class ContactAddress extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fontSize: 24,
      visible: false,
    }
    this.setFontSize = this.setFontSize.bind(this)
  }

  componentDidMount() {
    fitty(this.fittedTitle)
    this.fittedTitle.addEventListener('fit', this.setFontSize)

    setTimeout(() => {
      fitty.fitAll()
      this.setState({ visible: true })
    }, 300) // 600
  }

  componentWillUnmount() {
    this.fittedTitle.removeEventListener('fit', this.setFontSize)
  }

  setFontSize(e) {
    const { detail } = e
    const { newValue } = detail
    this.setState({ fontSize: newValue })
  }

  render() {
    const { items } = this.props
    const { fontSize, visible } = this.state
    return (
      <div
        className={[
          classes.list,
          styles.list,
          visible ? styles.visible : '',
        ].join(' ')}
      >
        {items.map(({ text, spans }, i) => {
          let url = null
          if (spans.length > 0 && spans[0].type === 'hyperlink') {
            url = spans[0].data.url
          }

          const content = url ? <a href={url}>{text}</a> : text

          const fittedItem = (
            <div className={[classes.fittedItem, styles.fittedItem].join(' ')}>
              <div ref={el => (this.fittedTitle = el)}>{content}</div>
            </div>
          )
          const resizedItem = <div style={{ fontSize }}>{content}</div>
          const item = hasLongestText(items, text) ? fittedItem : resizedItem
          return <div key={i}>{item}</div>
        })}
      </div>
    )
  }
}

ContactAddress.propTypes = {
  items: PropTypes.array.isRequired,
}

export default ContactAddress
