import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './ContactList.module.scss'

const classes = {
  list: [
    'mt-6',
    'sm:mt-12',
    'leading-tight',
    'font-sans-display',
    'lg:text-center',
    'sm:text-lg',
    'text-gold-100',
    //
  ].join(' '),
}

class ContactList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ visible: true })
    }, 600)
  }

  render() {
    const { items } = this.props
    const { visible } = this.state
    return (
      <div
        className={[
          classes.list,
          styles.list,
          visible ? styles.visible : '',
        ].join(' ')}
      >
        {items.map(({ text, spans }, i) => {
          let url = null

          if (spans.length > 0 && spans[0].type === 'hyperlink') {
            url = spans[0].data.url
          }

          const content = url ? <a href={url}>{text}</a> : text
          return <div key={i}>{content}</div>
        })}
      </div>
    )
  }
}

ContactList.propTypes = {
  items: PropTypes.array.isRequired,
}

export default ContactList
